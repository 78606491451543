/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import styles from './login.module.css';

function PhoneNumber({ phone, submit, setPhone,loading }) {
    const [focus, setFocused] = useState(false);

    const handleBlur = () => {
        setFocused(true)
    }

    const handlechange = (e) => {
        if(isNaN(e.target.value)){
            return;
        }
        setPhone(e.target.value);
    }


    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>
            <h1 className={styles.primaryHeading}>
                Welcome
            </h1>
            <p className={styles.description}>
                Please enter your mobile no. and we will send you an OTP to verify your number
            </p>
            <div style={{ position: 'absolute', marginTop: '17rem' }}>
                <form onSubmit={submit}>
                    <label style={{ display: 'flex', flexDirection: 'column', color: '#4D4D4D', fontSize: '.95rem' }} htmlFor="mobile">Mobile No.
                        <div className={styles.inputGroup}>
                            <span className={styles.prefix}>+91-</span>
                            <input required onBlur={handleBlur} focused={focus.toString()} value={phone} className={styles.phoneInput} type="text" pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$" maxLength={10} name='mobile' id="mobile" onChange={handlechange} />
                        </div>
                        <span className={`${styles.error} ${styles.errorPhone}`}>Enter a valid Phone number</span>
                    </label>
                    <button disabled={loading} type='submit' className={styles.submitButton}>Generate OTP</button>
                </form>
                <div style={{ width: '100%', }}>
                    <div style={{ width: '70%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1.2rem' }}><span style={{ width: '40%', borderTop: '1px solid #B4BE9C' }} /><span style={{ color: '#546C5F' }}>OR</span><span style={{ width: '40%', borderTop: '1px solid #B4BE9C' }} /></div>
                </div>
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', marginTop: '2rem' }}>
                    <div style={{ cursor: 'pointer' }}><a href={`${process.env.REACT_APP_GOOGLE_LOGIN_URL}`}><img src="/assets/images/google.svg" alt="" /></a></div>
                </div>
            </div>
        </div>
    )
}

export default PhoneNumber;