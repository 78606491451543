import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_CORE;

const generateOTP = async (data) => {
  try {
    const res = await axios.post('/public/auth/generateOtp', data);
    return res;
  } catch (err) {
    return err;
  }
};

const verifyOTP = async (data) => {
  try {
    const res = await axios.post('/public/auth/verifyOtp', data);
    return res;
  } catch (err) {
    return err;
  }
};

export { generateOTP, verifyOTP };
