import { createSlice } from '@reduxjs/toolkit';

const initialState = { page: 0 };
const retakeState = { page: 0, retake: true };

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    update(state, action) {
      const newState = { ...state, ...action.payload };
      return newState;
    },
    clear: () => initialState,
    retake: () => retakeState,
  },
});

export const { update, clear,retake } = quizSlice.actions;
export default quizSlice.reducer;
