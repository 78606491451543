/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showLoader: false
};

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showLoader: (state) => {
            state.showLoader = true
        },
        closeLoader: (state) => {
            state.showLoader = false;
        }
    }
});

// selectors 
export const loaderStatusSelector = (state) => state.loader.showLoader;

// actions
export const {showLoader, closeLoader} = loaderSlice.actions;

export default loaderSlice.reducer;