/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import { ThemeProvider, createTheme, RadioGroup, FormControlLabel, Radio, OutlinedInput, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FooterWeb from './footerWeb/FooterWeb';
import FooterMobile from './footerMobile/FooterMobile';
import styles from './footerWeb/footerWeb.module.css';
import { joinAsExpertRequest } from '../../api/public';
import { OMBHU_LOCATIONS, EXPERT_DOMAIN } from '../../constants/globalConstant';


function Footer() {
    const [modal, setModal] = useState(false);
    const [page, setpage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState(['']);
    const fileRef = useRef();

    const onUpload = () => {
        fileRef.current.click();
    };

    const onFileChange = (event) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reqObjj = { files: file };
        //     uploadFile(reqObjj, "meeting").then((res) => {
        //         const resObj = res.data;
        //         addFiles([...existingFiles, resObj.response.locationArr[0]]);
        //     });
        // }
    };
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const width = window.innerWidth;

    const theme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#3C4D43',
            }
        },
        typography: {
            fontSize: 20
        }
    })
    const schema = Yup.object(
        {
            name: Yup.string().required('Required'),
            profileType: Yup.string().required('Required'),
            phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
            email: Yup.string().email().required('Required'),
            domain: Yup.array().min(1, 'Required'),
            location: Yup.string().required('Required')
        }
    )

    const addBoard = async (data) => {
        setLoading(true)
        const newLinks = [...links].filter(item => item !== '')
        const response = await joinAsExpertRequest({ ...(newLinks.length > 0 && { links: newLinks }), ...data })
        if (response && response.status === 200) {
            setLoading(false);
            setpage(1)
        } else {
            setLoading(false);
            toast.error(response.response.data.statusMessage || response.message)
        }
    }

    const { values, handleChange, handleBlur, errors, handleSubmit, touched, resetForm } = useFormik({
        initialValues: {
            name: '',
            profileType: 'company',
            phoneNumber: '',
            email: '',
            domain: [],
            location: ''
        },
        validationSchema: schema,
        onSubmit: data => {
            addBoard(data)
        },
    });

    return (
        <>
            {width >= 825 && <FooterWeb setModal={setModal} setpage={setpage} />}
            {(width < 825) && <FooterMobile setModal={setModal} setpage={setpage} />}
            <Modal
                open={modal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >

                <div className={`${styles.modalContainer} scrollbar`} style={{ position: 'relative', maxHeight: '650px', overflow: 'auto' }}>
                    {/* <div style={{ position: 'absolute', left: '0', zIndex: '0', bottom: '0', overflow: 'hidden' }}><img style={{ objectFit: 'cover', width: '100%', opacity: '0.7' }} src="/assets/images/modalWaterMark.png" alt="" /></div> */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img role='presentation' onClick={() => { resetForm(); setModal(false) }} style={{ cursor: 'pointer' }} src="/assets/images/close.svg" alt="close" />
                    </div>
                    {page === 0 && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                        <p style={{ fontSize: '2.2rem', width: '80%', textAlign: 'center', marginTop: '2rem', color: '#3C4D43', fontWeight: '600' }}>Please fill in the details below to join us as an expert</p>
                        <form style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '80%', margin: '2rem' }} onSubmit={handleSubmit}>
                            <ThemeProvider theme={theme}>
                                <TextField style={{ width: '85%' }} id="outlined-password-input"
                                    label="Name*"
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    error={touched.name && errors.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={touched.name && errors.name}
                                />
                                <div style={{ width: '80%' }}>
                                    <RadioGroup
                                        name="profileType"
                                        value={values.profileType}
                                        onChange={handleChange}
                                    >
                                        <div style={{ marginTop: '1.6rem', justifyContent: 'space-between', display: 'flex' }}>
                                            <FormControlLabel sx={{ fontSize: '1.4rem', color: '#3C4D43' }} value="company" control={<Radio sx={{ fontSize: '2rem' }} />} label="Agency" />
                                            <FormControlLabel sx={{ fontSize: '1.6rem', color: '#3C4D43' }} value="individual" control={<Radio sx={{ fontSize: '2rem' }} />} label="Freelancer" />
                                        </div>
                                    </RadioGroup>
                                </div>
                                <div style={{ width: '85%', margin: '1.6rem auto' }}>
                                    <FormControl error={touched.domain && errors.domain} sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-name-label">Domain*</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            style={{ width: '100%', }}
                                            multiple
                                            value={values.domain}
                                            name="domain"
                                            error={touched.domain && errors.domain}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Domain" />}
                                        >
                                            {EXPERT_DOMAIN.map((domain) => (
                                                <MenuItem
                                                    key={domain.label}
                                                    value={domain.value}
                                                >
                                                    {domain.label.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {(touched.domain && errors.domain) && <FormHelperText htmlFor='render-select' error={errors.domain}>{errors.domain}</FormHelperText>}
                                    </FormControl>
                                    <TextField style={{ width: '100%', marginTop: '2rem' }} id="outlined-password-input"
                                        label="Email*"
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        error={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.email && errors.email}
                                    />
                                    <TextField style={{ width: '100%', marginTop: '2.6rem' }} id="outlined-password-input"
                                        label="Phone Number*"
                                        type="phone"
                                        name="phoneNumber"
                                        inputProps={{ maxLength: 10 }}
                                        value={values.phoneNumber}
                                        error={touched.phoneNumber && errors.phoneNumber}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                    <FormControl error={touched.location && errors.location} sx={{ width: '100%', marginTop: '2rem' }}>
                                        <InputLabel id="demo-multiple-name-label">Location*</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            // style={{ width: '100%'}}
                                            value={values.location}
                                            name="location"
                                            error={touched.location && errors.location}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Location" />}
                                        >
                                            {OMBHU_LOCATIONS.map((location) => (
                                                <MenuItem
                                                    key={location.label}
                                                    value={location.value}
                                                >
                                                    {location.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {(touched.location && errors.location) && <FormHelperText htmlFor='render-select' error={errors.location}>{errors.location}</FormHelperText>}
                                    </FormControl>

                                </div>
                                <div style={{ borderTop: '1px solid #D4DDBC', width: '85%', paddingTop: '1.6rem' }}>
                                    <div style={{ cursor: 'pointer', color: '#546C5F', fontWeight: '500', display: "flex", justifyContent: 'space-between' }} onClick={() => setLinks([...links, ''])}><p style={{ fontSize: '1.4rem' }}>Add Portfolio Link</p> <img style={{ width: '14px' }} src="/assets/icons/add.svg" alt="" /></div>
                                    <div>{links.map((item, index) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <TextField style={{ width: '90%', marginTop: '2rem' }} id="outlined-password-input"
                                            label={`Link ${index + 1}`}
                                            type="text"
                                            name="link"
                                            value={links[index]}
                                            onChange={(e) => {
                                                setLinks((link) => {
                                                    const newList = [...link];
                                                    newList[index] = e.target.value;
                                                    return newList;
                                                })
                                            }}
                                        />
                                        <img style={{ cursor: 'pointer' }} onClick={() => setLinks(link => {
                                            const newList = [...link]
                                            newList.splice(index, 1);
                                            return newList;
                                        })} src="/assets/icons/delete.svg" alt="delete" />
                                    </div>)}
                                    </div>
                                </div>

                                {/* <div style={{ borderTop: '1px solid #D4DDBC', width: '85%', paddingTop: '1.6rem', marginTop: '1.6rem' }}>
                                    <div style={{ cursor: 'pointer', color: '#546C5F', fontWeight: '500', display: "flex", justifyContent: 'space-between' }} onClick={onUpload}><p style={{ fontSize: '1.4rem' }}>Add Files</p> <img style={{ width: '14px' }} src="/assets/icons/add.svg" alt="" /><input
                                        type="file"
                                        multiple
                                        ref={fileRef}
                                        style={{ display: "none" }}
                                        onChange={onFileChange}
                                    /></div>
                                </div> */}

                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%', marginTop: '2rem' }}>
                                    <Button style={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }} variant="contained" type='submit'>Submit</Button>
                                </div>
                            </ThemeProvider>
                        </form>
                    </div>}
                    {page === 1 && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '2rem' }}>
                        <img style={{ width: '14rem' }} src="/assets/icons/success.gif" alt="success" />
                        <p style={{ marginTop: '1rem', fontSize: '2rem', textAlign: 'center' }}>Your details have been submitted</p>
                        <p className={styles.boldText}>Successfully</p>
                        <p className={styles.message}>You will receive a call from our BuildX.Homes representative shortly.</p>
                    </div>}
                </div>
            </Modal>
        </>
    )
}

export default Footer;