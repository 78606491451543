/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_HOME } from 'src/constants/routeConstants';
import styles from "./pageNotFound/pageNotFound.module.css";

const Thankyou = () => {
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        if (!state) {
            navigate(URL_HOME, { replace: true })
        }
    }, [state])

    return (
        <div style={{ width: '100%', height: '100vh', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '2rem' }}>
            <h4 style={{ textTransform: 'uppercase', fontSize: '1.6rem', fontWeight: '500', color: '#263238' }}>Your Details have been submitted</h4>
            <img style={{ objectFit: 'cover', maxWidth: '20rem', marginTop: '2rem', marginBottom: '2rem' }} src="/assets/icons/success.gif" alt="404" />
            <p style={{ fontSize: '2rem', fontWeight: '300', textAlign: 'center' }}>You will receive a call from our BuildX.Homes representative shortly.</p>
            <Button onClick={() => navigate(URL_HOME, { replace: true })} variant="contained" className={styles.homeButton}>
                Back To Home
            </Button>
        </div>
    )

}
export default Thankyou;