import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_CORE;

const getBlogs = async () => {
  try {
    const res = await axios.get(`/public/blog`);
    return res;
  } catch (err) {
    return err;
  }
};

const getBlogsPaginated = async (skipItems = 0, limit = 10, authoredByAdmin = false) => {
  try {
    const res = await axios.get(`/public/blog?skip=${skipItems}&limit=${limit}&authoredByAdmin=${authoredByAdmin}`);
    return res;
  } catch (err) {
    return err;
  }
};

const getArticles = async () => {
  try {
    const res = await axios.get(`/public/articles`);
    return res;
  } catch (err) {
    return err;
  }
};

const getBlogDetailsById = async (id) => {
  try {
    const res = await axios.get(`/public/blog/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const getExpertDetailsById = async (id) => {
  try {
    const res = await axios.get(`/public/expert/getExpertProfile/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const getPortfolioDetailsById = async (id) => {
  try {
    const res = await axios.get(`public/portfolio/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const joinAsExpertRequest = async (data) => {
  try {
    const res = await axios.post('/public/expert/join', data);
    return res;
  } catch (err) {
    return err;
  }
};
const getInspirationDetails = async (id, userId = null) => {
  try {
    let res;
    if (userId) {
      res = await axios.get(`public/inspiration/${id}?userId=${userId}`);
    } else {
      res = await axios.get(`public/inspiration/${id}`);
    }
    return res;
  } catch (err) {
    return err;
  }
};
const getInspirationsPaginated = async (skipItems = 0, limit = 10, queryItems = []) => {
  const query = queryItems.join(' ');
  try {
    const res = await axios.get(`/public/inspiration?skip=${skipItems}&limit=${limit}&q=${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

const getExperts = async () => {
  try {
    const res = await axios.get(`/public/expert/getFeaturedExperts`);
    return res;
  } catch (err) {
    return err;
  }
};

const getAnalytics = async () => {
  try {
    const res = await axios.get(`/public/getTopContent`);
    return res;
  } catch (err) {
    return err;
  }
};

const enquiryNowForm = async (data) => {
  try {
    const res = await axios.post('/public/lead/turnkeyEnquiry', data);
    return res;
  } catch (err) {
    return err;
  }
};

const assistForm = async (data) => {
  try {
    const res = await axios.post('/public/lead/projectEnquiry', data);
    return res;
  } catch (err) {
    return err;
  }
};

const getEstimate = async (data) => {
  try {
    const res = await axios.get(`/public/lead/getEstimate?name=${data.name}&email=${data.email}&phoneNumber=${data.phoneNumber}&location=${data.location}&noOfFloors=${data.floors}&area=${data.builtArea}`);
    return res;
  } catch (err) {
    return err;
  }
};

const postContactUs = async (data) => {
  try {
    const res = await axios.post(`/public/contactus`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export { getBlogs, getArticles, getBlogDetailsById, getExpertDetailsById, getPortfolioDetailsById, getBlogsPaginated, joinAsExpertRequest, getInspirationsPaginated, getInspirationDetails, getExperts, enquiryNowForm, getAnalytics, assistForm, getEstimate, postContactUs };
