/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_ABOUT, URL_FAQ, URL_PRIVACY_POLICY, URL_TEARMS_AND_CONDITIONS, URL_THANK_YOU } from '../../../constants/routeConstants';
import styles from './footerWeb.module.css';

function Footer({setModal}) {
  const navigate = useNavigate();
  const [data,setData]=useState('');

  return (<div className={styles.footerContainer}>
      <div className={styles.leftContent}>
        <div style={{ marginLeft: '8rem', marginTop: '2rem' }}>
          <img style={{width:'200px'}} src="/assets/images/buildLogoDark.svg" alt="logo" />
          <h2 className={`${styles.brand_company}`}>Tallam Shilpa Private Limited</h2>
          <h1>Subscribe to our Best kept secrets</h1>
          <div className={`${styles.newsletter}`}>
            <label htmlFor="email" style={{ display: 'grid', fontSize: '1.2rem', color: '#3C4D43', fontWeight: '400' }}>
              Email ID
              <input className={`${styles.inputName}`} value={data} onChange={(e)=>setData(e.target.value)} name="email" type="email" placeholder="Enter Your Email Id" />
            </label>

            <div className={`${styles.subscribe}`} onClick={()=>data && navigate(`${URL_THANK_YOU}`, { state: true, })}>Subscribe</div>
            <div style={{ marginTop: '3.5rem' }}>
              <h1>Stay Connected</h1>

              <ul className={`${styles.social}`}>
                <li className={`${styles.socialListItems}`}>
                  <img src="/assets/images/instagram.svg" alt="logo" />
                  <span className={`${styles.socialIcons}`} onClick={()=>window.open('https://www.instagram.com/buildx_homes/','_blank', 'rel=noopener noreferrer')}>Instagram</span>
                </li>
                <li className={`${styles.socialListItems}`} onClick={()=>window.open('https://www.facebook.com/buildxhomes','_blank', 'rel=noopener noreferrer')}>
                  <img src="/assets/images/facebook.svg" alt="logo" />
                  <span className={`${styles.socialIcons}`}>Facebook</span>
                </li>
                <li className={`${styles.socialListItems}`} onClick={()=>window.open('https://www.linkedin.com/company/buildx-homes/','_blank', 'rel=noopener noreferrer')}>
                  <img src="/assets/images/linkedin.svg" alt="logo" />
                  <span className={`${styles.socialIcons}`}>Linkedin</span>
                </li>
                <li className={`${styles.socialListItems}`} onClick={()=>window.open('https://www.youtube.com/channel/UCP-Qx7DFEc6DOiREfLyP65w','_blank', 'rel=noopener noreferrer')}>
                  <img src="/assets/images/youtube.svg" alt="logo" />
                  <span className={`${styles.socialIcons}`}>Youtube</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightCorner}>
        <div className={styles.rightContentContainer}>
          <div className={styles.rightContent}>
            <div className={`${styles.contactLinks}`}>
              <h4 className={`${styles.heading}`}>CONTACT</h4>
              <ul className={`${styles.footerLinks}`}>
                {/* <li style={{ display: 'flex', marginRight: '7px', marginTop: '6px' }}>
                  <img src="/assets/images/call.svg" alt="logo" />
                  <p href="www.google.com">+91 2222 8123 03 </p>
                </li> */}
                <li style={{ display: 'flex', marginRight: '7px', marginTop: '6px' }}>
                <span> <img src="/assets/images/call.svg" alt="logo" /></span>
                  <p>+91 99026 72727</p>
                </li>
                <li style={{ display: 'flex', marginRight: '7px', marginTop: '6px' }}>
                <span> <img src="/assets/images/email.svg" alt="logo" /></span>
                  <p>hello@buildx.homes</p>
                </li>
                <li style={{ display: 'flex', marginRight: '7px', marginTop: '6px' }}>
                  <span>
                    <img style={{width:  "11px",
height: "21px"}} src="/assets/images/Location.svg" alt="logo" />
                  </span>
                  <p>Tallam Shilpa Private Limited, 5, 27th Cross Road, 6th Main Rd, Jayanagar 3rd Block Bangalore, Karnataka, India 560011 </p>
                </li>
              </ul>
            </div>
            <div className={`${styles.companyLinks}`}>
              <h4 className={`${styles.heading}`}>COMPANY</h4>
              <ul className={`${styles.footerLinks}`}>
                <li role="presentation" onClick={() => navigate(URL_ABOUT)}>
                  About Us
                </li>
                <li role="presentation" onClick={() => navigate(URL_PRIVACY_POLICY)}>
                  Privacy Policy
                </li>
                <li role="presentation" onClick={() => navigate(URL_TEARMS_AND_CONDITIONS)}>
                  Terms & Conditions
                </li>
                {/* <li role="presentation" onClick={() => navigate(URL_FAQ)}>
                  FAQ
                </li> */}
                <li role="presentation" onClick={() => setModal(true)}>
                  Join Us As An Expert
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
