import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    decrement(state, action) {
      let newState = state;
      newState -= action.payload;
      return newState;
    },
    update(state, action) {
      const newState = { ...state, ...action.payload };
      localStorage.setItem("user", JSON.stringify(newState));
      return newState;
    },
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    // From here we can take action only at this "counter" state
    // But, as we have taken care of this particular "logout" action
    // in rootReducer, we can use it to CLEAR the complete Redux Store's state
  },
});

export const { update, decrement, logout } = userSlice.actions;
export default userSlice.reducer;
