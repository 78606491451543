import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_CORE;

const getUserDetails = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/user`);
    return res;
  } catch (err) {
    return err;
  }
};

const updateUser = async (id, token, data) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.put(`/user/${id}`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const blogLike = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/blog/addLike`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const blogUnlike = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/blog/removeLike`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const portfolioLike = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/portfolio/addLike`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const portfolioUnlike = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/portfolio/removeLike`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const portfolioComment = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/portfolio/addComment`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const blogComment = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/blog/addComment`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const getRandomSuggetions = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/quiz/getRandomSuggestions`);
    return res;
  } catch (err) {
    return err;
  }
};

const getPersonalisedSuggestions = async (pictureId, rooms, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/quiz/getPersonalisedSuggestions?pictureIds=${pictureId.join(',')}&rooms=${rooms.join(',')}`);
    return res;
  } catch (err) {
    return err;
  }
};

const getInspirationResult = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/quiz/getFinalStyle?pictureIds=${data.pictureIds}`);
    return res;
  } catch (err) {
    return err;
  }
};

const saveInspirationResult = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/quiz/save`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const getQuiz = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/quiz/getQuizResults`);
    return res;
  } catch (err) {
    return err;
  }
};

const inspirationLike = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/inspiration/addLike`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const inspirationUnLike = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/inspiration/removeLike`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const inspirationComment = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/inspiration/addComment`, data);
    return res;
  } catch (err) {
    return err;
  }
};

const createBoard = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/board`, data);
    return res;
  } catch (err) {
    return err;
  }
};
const getAllBoardByUser = async (token, id = null) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    let res;
    if (id) {
      res = await axios.get(`/board?inspirationId=${id}`);
    } else {
      res = await axios.get(`/board`);
    }
    return res;
  } catch (err) {
    return err;
  }
};

const getBoardDetailsById = async (token, id) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/board/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const removeBoard = async (token, id) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.delete(`/board/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const addInspirationToBoard = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.put(`/board/updateInspirationInBoard`, data);
    return res;
  } catch (err) {
    return err;
  }
};
const getExperts = async (token, url) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(url);
    return res;
  } catch (err) {
    return err;
  }
};
const scheduledAConsultant = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/lead/contactExpert`, data);
    return res;
  } catch (err) {
    return err;
  }
};
const getContactedExperts = async (token, id) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/log/expertsContacted/${id}`, {
      baseURL: `${process.env.REACT_APP_API_URL}/AUDIT`,
    });
    return res;
  } catch (err) {
    return err;
  }
};
const rateExpert = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.post(`/user/rateExpert`, data);
    return res;
  } catch (err) {
    return err;
  }
};
const getPaymentById = async (token, id) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/project/getPaymentSchedule/${id}`, {
      baseURL: `${process.env.REACT_APP_API_URL}/PM`,
    });
    return res;
  } catch (err) {
    return err;
  }
};

const getExpertSuggetion = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  try {
    const res = await axios.get(`/quiz/getExpertSuggestions?location=${data.location}&style=${data.style}`);
    return res;
  } catch (err) {
    return err;
  }
};

export { getQuiz, blogLike, updateUser, blogUnlike, blogComment, removeBoard, createBoard, portfolioLike, getUserDetails, portfolioUnlike, inspirationLike, portfolioComment, getAllBoardByUser, inspirationUnLike, inspirationComment, getRandomSuggetions, getBoardDetailsById, getInspirationResult, addInspirationToBoard, getPersonalisedSuggestions, getExperts, scheduledAConsultant, getContactedExperts, rateExpert, getPaymentById, saveInspirationResult, getExpertSuggetion };
