import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './pageNotFound.module.css';
import { URL_HOME } from '../../constants/routeConstants';

function PageNotFound() {
    const navigate = useNavigate();
    return (
        <div style={{ width: '100%', height: '100vh', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '2rem' }}>
            <h4 style={{ textTransform: 'uppercase', fontSize: '1.6rem', fontWeight: '500', color: '#263238' }}>page not found</h4>
            <img style={{ marginTop: '-4rem', objectFit: 'cover', maxWidth: '35rem' }} src="/assets/images/404.svg" alt="404" />
            <p style={{ fontSize: '2rem', fontWeight: '300', marginTop: '-3rem', textAlign: 'center' }}>Uh Oh, We can’t seem to find the page you are looking for.</p>
            {/* <Button onClick={() => navigate(URL_HOME)} variant="contained" className={styles.homeButton}>
                Back To Home
            </Button> */}
        </div>
    )
}

export default PageNotFound;