import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import './index.css';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from "./store/store";
import 'react-toastify/dist/ReactToastify.css';
import { injectStore } from './utils/axiosInstance';
// import reportWebVitals from './reportWebVitals';
injectStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </BrowserRouter>
      </PersistGate>
      <ToastContainer theme="colored"/>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
