import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoutes() {

  const selector = useSelector((state) => state.user);

  const isAuthenticated = !!(selector.id && selector.name && selector.email && selector.phoneNumber);
  // const isAuthenticated = localStorage.getItem("isAuthenticated", 'true');
  // console.log(isAuthenticated, 'is auth');

  return (
    // for now its accessible by hardcoading local storage
    isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  );
}

export default ProtectedRoutes;