import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Accordion, AccordionSummary } from '@mui/material';
import { dateFormatter, capitalizeWord } from 'src/constants/helper';
import Icon from '@mdi/react';
import { getPaymentById } from 'src/api';
import moment from 'moment';
import { mdiMenuUp } from '@mdi/js';
import NoContent from 'src/components/NoContent';
import styles from './upcomingPayment.module.css';

const accordionStyles = {
  root: `${styles.accordian_styles}`,
};
const columns = [
  {
    field: 'stage',
    headerName: 'Stage Name',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'start',
    renderCell: (params) => capitalizeWord(params.row.stage),
  },
  {
    field: 'amount',
    headerName: 'Amount ',
    disableColumnMenu: true,
    flex: 1,
    align: 'start',
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    align: 'start',
    renderCell: (params) => {
      const timeDiff = moment(params.row.transactionDate).diff(moment(params.row.dueDate), 'days');
      return <p style={{ color: ' #A17217', marginTop: '15px' }}>{timeDiff > 0 ? `By ${timeDiff}  days` : '--'}</p>;
    },
  },
  { field: 'status', headerName: 'Status', flex: 1, align: 'start' },
];

const UpcomingPayment = () => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  const [item, setItem] = useState([]);
  const selector = useSelector((state) => state.user);
  const params = useParams();

  const { id } = params;

  const paymentPlan = async () => {
    setLoading(true);

    const response = await getPaymentById(selector.token, id);
    if (response && response.status === 200) {
      const data = response.data.response;
      setItem(data);
      if (data.payments.pending.length > 0) {
        setPending(data.payments.pending);
      } else {
        setPending([]);
      }
      setLoading(false);
    } else {
      toast.error(response?.response?.data?.statusMessage || response?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    paymentPlan();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className={styles.topItem}>
        <div style={{ textAlign: 'center' }}>
          <p className={styles.dataTable}>Paid Amount</p>
          <p style={{ color: '#07794C', fontWeight: '600', fontSize: '20px', lineHeight: '32px' }}>{item?.total}</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className={styles.dataTable}>Balance Amount</p>
          <p style={{ color: '#8A1E08', fontWeight: '600', fontSize: '20px', lineHeight: '32px' }}>{item?.pending}</p>
        </div>
      </div>
      {/* eslint-disable-next-line react/button-has-type */}

      <Accordion classes={accordionStyles} defaultExpanded>
        <AccordionSummary
          style={{ backgroundColor: '#EDF7F2', color: 'black' }}
          className={styles.dataTable}
          expandIcon={<Icon path={mdiMenuUp} size={0.8} color="#808080" />}
        >
          Upcoming Payments
        </AccordionSummary>
        {pending.length === 0 ? (
          <NoContent src="/assets/images/house.svg" title="No data available" isSmall />
        ) : (
          <DataGrid
            columns={columns}
            rows={pending}
            getRowId={(row) => row._id}
            className={styles.dataTable}
            autoHeight
            disableSelectionOnClick
            pagination={false}
            sx={{
              '& .MuiTablePagination-toolbar': {
                display: 'none',
              },
            }}
          />
        )}
      </Accordion>
    </div>
  );
};

export default UpcomingPayment;
