import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Accordion, AccordionSummary } from '@mui/material';
import { dateFormatter, capitalizeWord } from 'src/constants/helper';
import Icon from '@mdi/react';
import { getPaymentById } from 'src/api';
import moment from 'moment';
import { mdiMenuUp } from '@mdi/js';
import { useParams } from 'react-router-dom';
import NoContent from 'src/components/NoContent';
import styles from './completedPayment.module.css';

const accordionStyles = {
  root: `${styles.accordian_styles}`,
};

const CompletedPayment = () => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState([]);
  const { id } = params;
  const selector = useSelector((state) => state.user);
  const paymentPlan = async () => {
    setLoading(true);

    const response = await getPaymentById(selector.token, id);
    if (response && response.status === 200) {
      const data = response.data.response;
      const rows = data?.payments?.paid || [];
      setPaid(rows);
      setLoading(false);
    } else {
      toast.error(response?.response?.data?.statusMessage || response?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    paymentPlan();
  }, []);
  const columns = [
    {
      field: 'stage',
      headerName: 'Stage Name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      align: 'start',
      renderCell: (params) => capitalizeWord(params.row.stage),
    },
    {
      field: 'amount',
      headerName: 'Amount ',
      disableColumnMenu: true,
      flex: 1,
      align: 'start',
    },
    {
      field: 'delay',
      headerName: 'Delay',
      flex: 1,
      align: 'start',
      renderCell: (params) => {
        const timeDiff = moment(params.row.transactionDate).diff(moment(params.row.dueDate), 'days');
        return <p style={{ color: ' #A17217', marginTop: '15px' }}>{timeDiff > 0 ? `By ${timeDiff}  days` : '--'}</p>;
      },
    },
    { field: 'modeOfPayment', headerName: 'Mode Of Payment', flex: 1, align: 'start' },
    {
      field: 'paymentRef',
      headerName: 'Payment Details',
      flex: 1,
      align: 'start',
      renderCell: (params) => <p>{params.row?.paymentRef}</p>,
    },
    {
      field: 'transactionDate',
      headerName: 'Transaction Date',
      flex: 1,
      align: 'start',
      renderCell: (params) => <p> {dateFormatter(params.row?.transactionDate)}</p>,
    },
  ];

  return (
    <Accordion classes={accordionStyles} defaultExpanded>
      <AccordionSummary
        style={{ backgroundColor: '#EDF7F2', color: 'black' }}
        className={styles.dataTable}
        expandIcon={<Icon path={mdiMenuUp} size={0.8} color="#808080" />}
      >
        Completed Payments
      </AccordionSummary>
      {paid.length === 0 ? (
        <NoContent src="/assets/images/house.svg" title="No data available" isSmall />
      ) : (
        <DataGrid
          columns={columns}
          rows={paid}
          getRowId={(row) => row._id}
          className={styles.dataTable}
          pagination={false}
          autoHeight
          disableSelectionOnClick
          pageSize={1000000}
          sx={{
            '& .MuiTablePagination-toolbar': {
              display: 'none',
            },
            '& ..MuiDataGrid-cell ': {
              alignItems: 'center',
              justifyContent: 'start',
            },
          }}
        />
      )}
    </Accordion>
  );
};

export default CompletedPayment;
