import React from 'react';
import { Card } from '@mui/material';
import UpcomingPayment from './upcomingPayment/UpcomingPayment';
import CompletedPayment from './completedPayment/CompletedPayment';

const PaymentPlan = () => {
  return (
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <Card style={{ padding: '2rem' }}>
        <UpcomingPayment />
      </Card>
      <Card style={{ padding: '2rem' }}>
        <CompletedPayment />
      </Card>
    </div>
  );
};

export default PaymentPlan;
