import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import Verify from './Verify';
import Register from './Register';
import PhoneNumber from './PhoneNumber';

import { getUserDetails } from '../../api';
import { update } from '../../store/userSlice';
import { generateOTP, verifyOTP } from '../../api/auth';

import styles from './login.module.css';
import { URL_HOME } from '../../constants/routeConstants';



function Login() {

    const [phone, setPhone] = useState('');
    const [param] = useSearchParams();
    const token = param.get('token');
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const createOTP = async () => {
        setLoading(true);
        const response = await generateOTP({ phoneNumber: phone });
        if (response && response.status === 200) {
            setLoading(false);
            setPageNumber(1);
        } else {
            setLoading(false);
            toast.error(response.message)
        }
    }

    const getUser = async (tkn) => {
        const response = await getUserDetails(tkn);
        if (response && response.status === 200) {
            const data = response.data.response;
            if (data.isGoogle) {
                dispatch(update({ name: data.profileMeta.name, email: data.email, pincode: data.profileMeta.pincode, profilePicture: data.profileMeta.profilePicture, phoneNumber: data.phoneNumber, id: data._id, isGoogle: data.isGoogle }))
                if (data.profileMeta.name && data.phoneNumber && data.profileMeta.pincode) {
                    // if (data.quizTaken) {
                    //     navigate('/');
                    // } else {
                    //     navigate('/quiz');
                    // }
                    if (param.get('redirect')) {
                        navigate(param.get('redirect'))
                    } else {
                        navigate(URL_HOME)
                    }

                } else {
                    setPageNumber(2);
                }
            } else {
                dispatch(update({ name: data.profileMeta.name, email: data.email, profilePicture: data.profileMeta.profilePicture, pincode: data.profileMeta.pincode, phoneNumber: data.phoneNumber, id: data._id, isGoogle: data.isGoogle }))
                if (data.profileMeta.name && data.email && data.profileMeta.pincode) {
                    if (param.get('redirect')) {
                        navigate(param.get('redirect'))
                    } else {
                        navigate(URL_HOME)
                    }
                } else {
                    setPageNumber(2);
                }
            }
        } else {
            toast.error(response.response.data.statusMessage || response.message)
        }
    }

    useEffect(() => {
        if (token) {
            dispatch(update({ token }))
            getUser(token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validateOTP = async (otp) => {
        setLoading(true);
        const response = await verifyOTP({ phoneNumber: phone, otp });
        if (response && response.status === 200) {
            // localStorage.setItem("ombhu-user-token", response.data?.response?.token)
            dispatch(update({ ...response.data?.response }));
            getUser(response.data?.response?.token);
            setLoading(false);
        } else {
            setLoading(false);
            toast.error(response.response.data.statusMessage || response.message)
        }
    }

    const submitPhoneNumber = (e) => {
        e.preventDefault();
        createOTP()
    }

    return (
        <div className={styles.container} style={{ backgroundImage: 'url("/assets/images/loginBackground.png")' }}>
            <div className={styles.contentRow}>
                <div className={styles.whiteContainer}>
                    <img style={{ position: 'absolute', bottom: '0', right: '0' }} src="/assets/images/loginHero.svg" alt="hero" />
                    <img className={styles.leafDecor} src="/assets/images/leafDecor.svg" alt="hero" />
                    {pageNumber !== 2 && <div role="presentation" onClick={pageNumber === 1 ? () => setPageNumber(0) : () => navigate(URL_HOME)} style={{ cursor: 'pointer', width: '100%', height: '6%', display: 'flex', alignItems: 'center', paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '2rem', justifyContent: 'flex-start', fontSize: '3rem' }}>
                        <img src="/assets/images/back.svg" alt="back" />
                    </div>}
                    {pageNumber === 0 && <PhoneNumber loading={loading} phone={phone} submit={submitPhoneNumber} setPhone={setPhone} />}
                    {pageNumber === 1 && <Verify loading={loading} generateOTP={createOTP} verifyOTP={validateOTP} phone={phone} />}
                    {pageNumber === 2 && <Register phone={phone} />}
                </div>
            </div>
        </div >
    )
}

export default Login;