import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar/Navbar';

function Layout({ navbar, footer, children }) {
  return (
    <>
      {navbar && <Navbar />}
      {children}
      {footer && <Footer />}
    </>
  );
}

export default Layout;
