/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import styles from './login.module.css';

function Verify({ phone, verifyOTP, generateOTP,loading }) {

    const [otp, setOtp] = useState(new Array(4).fill(''))

    const handleOtp = (e, index) => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(e.target.value)) {
            return;
        }
        const newOtp = [...otp]
        newOtp[index] = e.target.value;
        setOtp(newOtp);
        if (newOtp[index]) {
            e.target.nextSibling?.focus()
        }
        else {
            e.target.previousSibling?.focus()
        }
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative' }} >
            <h1 className={styles.primaryHeading}>
                Welcome
            </h1>
            <p className={styles.description}>
                {`We have sent an OTP to +91 ${phone}. Please enter the code below`}
            </p>
            <div style={{ display: 'flex', marginTop: '4rem', marginBottom: '2rem', gap: '1.4rem' }}>
                {otp.map((data, index) => (
                    <input autoFocus={index === 0 && true} onFocus={(e) => e.target.select} className={styles.otpInput} placeholder='-' maxLength={1} key={index} value={otp[index]} onChange={(e) => handleOtp(e, index)} />
                ))}
            </div>
            <div style={{ textAlign: 'center', fontSize: '1.4rem' }}><span>Didn’t get the code? </span><span role="presentation" style={{ fontWeight: 500, cursor: 'pointer' }} onClick={() => generateOTP()}> Resend OTP</span></div>
            <Button disabled={otp.length !== 4 || loading} onClick={() => verifyOTP(otp.join(''))} type='button' className={styles.headerButton}>Verify</Button>
        </div>
    )

}
export default Verify;