import React from 'react';
import Modal from '@mui/material/Modal';
import { ThemeProvider, createTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import styles from './loginRequestModal.module.css';

function LoginRequestModal({ modal, setModal }) {
    const navigate = useNavigate()

    const theme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#3C4D43',
            },
        },
        typography: {
            fontSize: 18,
        },
    });

    return (
        <Modal
            open={modal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className={styles.modal}
        >
            <div
                className={styles.modalContainer}
                style={{ backgroundImage: 'url("/assets/images/quizWatermark.png")' }}
            >
                <div className={styles.closeButton}>
                    <img
                        role='presentation'
                        onClick={() => {
                            setModal(false);
                        }}
                        src='/assets/images/close.svg'
                        alt='close'
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
                    <img src="/assets/images/loginRequest.svg" alt="warning" />
                </div>
                <div>
                    <p style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center', width: '70%', textAlign: 'center', margin: '0 auto' }}>
                        Please Sign In to your account to perform this action.
                    </p>
                </div>
                <ThemeProvider theme={theme}>
                    <div className={styles.modalButtonContainer}>
                        <Button
                            onClick={() => {
                                setModal(false);
                            }}
                            variant="outlined"
                            type="button"
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => {navigate(`/login?redirect=${window.location.pathname}`);setModal(false)}} variant="contained" type='button'>
                            Sign In
                        </Button>
                    </div>
                </ThemeProvider>
            </div>
        </Modal>
    );
}
export default LoginRequestModal;