// Primary URL
export const URL_PROFILE = "/profile";
export const URL_FEEDS = "/feeds";
export const URL_HOME = "/";
export const URL_THANK_YOU = "/thankyou";
export const URL_LOGIN = "/login";
export const URL_EXPERTS = "/experts";
export const URL_INSPIRATION = "/inspiration";
export const URL_TEARMS_AND_CONDITIONS = "/terms-conditions";
export const URL_CONTACT = "/contact";
export const URL_ABOUT = "/about";
export const URL_PRIVACY_POLICY = "/privacy-policy";
export const URL_FAQ = "/faq";
export const URL_BLOG = "/blog";
export const URL_QUIZ = "/quiz";
export const URL_ARTICLE = "/article";
export const URL_PAGE_NOT_FOUND = "/404";
export const URL_ESTIMATE = "/estimate";
export const URL_CHECKLIST = "/checklist";
export const URL_PAYMENT_PLAN = "/paymentPlan";

// secondary URL

export const URL_CATEGORY = '/category';
export const URL_ALL_ARTICLES = '/all-articles';
export const URL_ALL_BLOGS = '/all-blogs';
export const URL_PORTFOLIO = '/portfolio';
export const URL_EDIT = '/edit';
export const URL_INSPIRATION_BOARD = '/inspiration-board';
export const URL_EXPERT_CONTECTED = '/experts-contacted';


// dynamic URL

export const DYNEMIC_ID = "/:id";
export const DYNEMIC_PORTFOLIO_ID = "/:portfolioId";

// timeline

export const URL_PROJECTS = "/projects";
export const URL_TIMELINE = "/timeline";
