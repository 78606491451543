import moment from "moment";

export const isCurrentMonth = (monthNumber, year) => {
  const currentDate = moment();
  const targetDate = moment({ year, month: monthNumber - 1 });

  return currentDate.isSame(targetDate, "month");
};

export const getNumOfDays = (startDate, endDate) =>
  moment(endDate).diff(moment(startDate), "days");

export const dateFormatter = (dateString) => {
  return moment(dateString).format("DD MMM YYYY");
};

export const capitalizeWord = (str) =>
  str
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const isDateInRange = (startDate, endDate) => {
  const currentDate = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return currentDate.isBetween(start, end, null, "[]");
};

export const generateSubSection = (currentYear, currentMonth, type) => {
  const monthStart = moment({ year: currentYear, month: currentMonth });
  const monthEnd = moment(monthStart).endOf("month");

  if (type === "day") {
    return Array.from({ length: monthEnd.date() }, (_, i) => {
      const date = moment(monthStart).add(i, "days").format("YYYY-MM-DD");
      return { day: moment(date).format("DD"), date };
    });
  }
  if (type === "week") {
    const weeksInMonth = Math.ceil(monthEnd.date() / 7);
    return Array.from({ length: weeksInMonth }, (_, i) => {
      const startOfWeek = moment(monthStart).add(i * 7, "days");
      const endOfWeek = moment(startOfWeek).add(6, "days");
      const weekStart = startOfWeek.format("YYYY-MM-DD");
      const weekEnd = endOfWeek.isBefore(monthEnd)
        ? endOfWeek.format("YYYY-MM-DD")
        : monthEnd.format("YYYY-MM-DD");
      return { day: `w${i + 1}`, date: `${weekStart} - ${weekEnd}` };
    });
  }

  return [];
};
export const getMonthsAndSubSectionInRange = (
  startDate,
  endDate,
  type = "day"
) => {
  const months = [];

  let currentMonth = startDate.month();
  let currentYear = startDate.year();

  while (
    currentYear < endDate.year() ||
    (currentYear === endDate.year() && currentMonth <= endDate.month())
  ) {
    const month = {
      name: moment({ year: currentYear, month: currentMonth }).format("MMM"),
      year: currentYear,
      monthNumber: currentMonth + 1,
      subSection: generateSubSection(currentYear, currentMonth, type),
    };

    months.push(month);

    currentMonth += 1;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear += 1;
    }
  }

  return months;
};
